'use strict';

let $ = require('jquery');

import Config from 'Config';
import MobileRequest from './protocol/request/MobileRequest';
import GetStatusRequest from './protocol/request/task/GetStatus';
import MobileMessage from './protocol/MobileMessage';
import I18NUtils from 'utils/i18n/I18NUtils';
import User from 'User';
import ModalConfirmationDialog from '../views/modal/ModalConfirmationDialog';
import { MessageResponse, TaskRequest } from './protocol/request/task/TaskRequest';
import App from 'App';
import DialogUtils from '../utils/DialogUtils';

class Server {
    private static _instance: Server;
    private handleInternalError : any;
    private handleMissingAuth : any;
    private requests : Array<any>;
    private token : string;
    private pendingCfgPromises: { [requestUrl: string]: Promise<any> };
    private sessionTimeoutId : any;

    private constructor() {
        this.requests = [];
        this.pendingCfgPromises = {};
    }

    public static get Instance(): Server {
        return this._instance || (this._instance = new this());
    }

    public setGlobalFailureCallback(globalFailureCallback) {
        this.handleInternalError = globalFailureCallback;
    }

    public setGlobalAuthenticationMissingCallback(callback) {
        this.handleMissingAuth = callback;
    }

    getTokenedUrl(url : string) : string {
        if (!url) {
            return '';
        }

        //In case of private images or any other url given, we put the token in the url
        //In case of public images, we'll not put the token as the images are not private
        if (!this.hasToken() || url.indexOf('public/') !== -1 || url.indexOf('configuration/') !== -1) {
            return Config.serverUrl + '/' + url;
        } else {
            return Config.serverUrl + '/' + this.token + '/' + url;
        }
    };

    hasToken() {
        return this.token && this.token.length > 0;
    }

    setToken(token) {
        this.token = token;
        sessionStorage.saiUserToken = JSON.stringify(token);
    }

    private getMessageUrl(requestMessage): string {
        return Config.serverUrl + '/' + requestMessage.getUrl();
    }

    public performRequest(requestMessage: MobileRequest): Promise<any> {
        //Some request results need extra resources to be initialized as
        //pre-processing. A message can thus contain multiple sub messages
        //that need to be fetched at the same time. The processing will occur
        //in the preProcess function of the message
        let allRequestsMessages = [ requestMessage ];
        if(requestMessage.hasSubRequests()) {
            allRequestsMessages = allRequestsMessages.concat(requestMessage.getSubRequests());
        }
        let allPromises = [];
        //Note : Configuration request promises will always be unique so that multiple calls to the same configuration
        //will result in only one promise that will resolve all request at once.
        let me = this;
        for(let key in allRequestsMessages) {
            let reqMessage = allRequestsMessages[key];
            let finalUrl = this.getMessageUrl(reqMessage);
            let isConfigCall = finalUrl.indexOf('configuration/') > 0;
            if( !isConfigCall || (isConfigCall && this.pendingCfgPromises[finalUrl] === undefined) ) {
                let remoteCall = new Promise((resolve, reject) => {
                    let type = reqMessage.getMethod();
                    if (type === undefined) {
                        console.warn('Missing type in ' + reqMessage.getName() + ' message, assuming GET');
                        type = 'GET';
                    }

                    let device = Config.deviceType || 'web';
                    let data = {};
                    let requestParams = reqMessage.getSerializable();

                    for (let key in reqMessage.getSerializable()) {
                        if (typeof requestParams[key] !== 'string') {
                            data[key] = JSON.stringify(requestParams[key]);
                        } else {
                            data[key] = requestParams[key];
                        }
                    }
                    data['sessionVars'] = JSON.stringify(reqMessage.getSessionVars());
                    data['device'] = device;

                    var failureCB = function (result) {
                        reject(result.responseJSON.message);
                    };

                    this.performSecuredAjax({
                        cleanUrl: finalUrl,
                        url: finalUrl,
                        beforeSend: function() {
                            //We might already have cached the answer
                            if(typeof(Storage) !== undefined) {
                                let item = localStorage.getItem(reqMessage.getUrl());
                                if(item !== null) {
                                    //immediately resolving the promise
                                    resolve(JSON.parse(item));
                                    //The call won't be performed
                                    return false;
                                }
                                return true
                            }
                        },
                        success: function (result, status, responseObject) {
                            me.setSessionTimeout();
                            if(result.status === 202 || result.status === 201) {
                                //201 - ongoing, question will be asked before sending
                                //the request again with the answer
                                //202 - long processing, the handling will be done
                                //through another async callback
                                return;
                            }
                            //If we can resolve the result, we cache it
                            let cacheHeader = responseObject.getResponseHeader('cache-control');
                            let canCache = cacheHeader !== undefined && cacheHeader.indexOf('no-cache') < 0;
                            if(typeof(Storage) !== undefined && canCache) {
                                try{
                                    localStorage.setItem(reqMessage.getUrl(), JSON.stringify(result));
                                } catch(e) {
                                    console.warn('No more quota for local storage for configuration, new entries will always be fetched on the fly');
                                }
                            }

                            me.checkForMessageAndResolve(resolve, result);
                        },
                        statusCode: {
                            500: failureCB,
                            502: failureCB,
                            400: failureCB
                        },
                        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                        type: type,
                        crossDomain: true,
                        data: data,
                        dataType: 'json',
                        cache: true,
                        headers: this.hasToken() ? {
                            'X-Token' : this.token
                        } : {},
                        timeout: 120000 // 120s timeout
                    }, resolve, reject, reqMessage);
                });

                if(isConfigCall) {
                    this.pendingCfgPromises[finalUrl] = remoteCall;
                } else {
                    return remoteCall;
                }
            }
            allPromises.push(this.pendingCfgPromises[finalUrl]);
        }
        return new Promise((accept, reject) => {
            Promise.all(allPromises)
                .then((results) => {
                    if(requestMessage['preProcess']) {
                        requestMessage['preProcess'](results);
                    }
                    accept(results[0]);
                })
                .catch(reject);
        });
    }

    private checkForMessageAndResolve(resolve, result) {
        let task = result.task;
        let foundMessage = undefined;
        if(task) {
            let ongoingMessages: Array<any> = result.task.messages;
            if(ongoingMessages && ongoingMessages.length > 0) {
                foundMessage = ongoingMessages[0];
            }
        }

        if(foundMessage) {
            let userMessage = foundMessage.userMessage;
            DialogUtils.displayDialog({
                title: '',
                message: userMessage,
                type: 'OK'
            });
        }
        resolve(result);
    }

    performSecuredAjax(requestData, resolve, reject, message, statusMessage?: GetStatusRequest) {
        requestData.statusCode['201'] = this.handleOngoing.bind(this, message, resolve, reject);
        requestData.statusCode['202'] = this.handleLongProcessingRequest.bind(this, message, resolve, reject, statusMessage);
        requestData.statusCode['401'] = this.handleMissingAuth.bind(this, message, resolve, reject);
        requestData.statusCode['403'] = this.handleMissingAuth.bind(this, message, resolve, reject);
        $.ajax(requestData);
    }

    private handleOngoing(message:MobileRequest, resolve, reject, result) {
        //Extracting message from the task descriptor
        let task = result.task;
        let screen = result.screen;
        if(!task && !screen) {
            reject(I18NUtils.getMessage('ONGOING_NO_TASK', User.getLocale(), {}));
        }
        let ongoingMessages: Array<any> = task ? task.messages : screen.messages;
        if(!ongoingMessages) {
            reject(I18NUtils.getMessage('ONGOING_NO_MESSAGE', User.getLocale(), {}));
        }
        if(ongoingMessages.length !== 1) {
            reject(I18NUtils.getMessage('ONGOING_MULTIPLE_MESSAGE', User.getLocale(), {}));
        }
        let ongoingMessage = ongoingMessages[0];
        let userMessage = ongoingMessage.userMessage;
        let userDetails = ongoingMessage.userDetailsMessage;
        let requests: Array<any> = ongoingMessage.requests;
        let buttons: Array<any> = ongoingMessage.buttons;

        if(!requests) {
            reject(I18NUtils.getMessage('ONGOING_NO_REQUEST', User.getLocale(), {}));
        }
        if(requests.length !== 1) {
            reject(I18NUtils.getMessage('ONGOING_MULTIPLE_REQUEST', User.getLocale(), {}));
        }
        let requestToDisplay = requests[0];
        if(!requestToDisplay.id) {
            requestToDisplay.id = ongoingMessage.code;
        }

        //Asking the user for an answer
        DialogUtils.displayDialog({
            title:'',
            message: userMessage,
            type: requestToDisplay.type,
            buttons: buttons
        }, (action) => {
            if(action === 'cancel') {
                reject(ModalConfirmationDialog.SYSTEM_CANCEL_MESSAGE);
            } else {
                //Inserting the response as a response context
                let response: MessageResponse = {
                    status: 'ONGOING',
                    type: requestToDisplay.type,
                    code: ongoingMessage.code,
                    response: [{
                        id: requestToDisplay.id,
                        type: requestToDisplay.type,
                        response: action.toUpperCase()
                    }]
                };
                //Calling back the request again
                (message as TaskRequest).setMessageContext(response);

                this.performRequest(message)
                    .then(resolve)
                    .catch(reject);
            }
        }, reject);
    }

    handleLongProcessingRequest(message:MobileRequest, resolve, reject, statusMessage: GetStatusRequest, result) {
        let me = this;
        let status = statusMessage ? result.processStatus : 'INIT';
        if(status === 'SUCCESS') {
            //This is the end, we need to make the initial call again to get the result
            message.getSessionVars()['FM_PROCESS_ID'] = statusMessage.getPid();
            this.performRequest(message).then(statusMessage.getInitialMessageResolve())
                .catch((error) => {
                    let reject = statusMessage.getInitialMessageReject();
                    reject(error);
                })
        } else if(status === 'FAILURE') {
            reject(result.uiMessage);
        } else if(status === 'INIT' || status === 'RUNNING') {
            let previousValue = status === 'INIT' ? 0 : statusMessage.getTime();
            if(previousValue === 0) {
                previousValue = 200;
            } else if(previousValue === 200) {
                previousValue = 1000;
            } else if(previousValue === 1000) {
                previousValue = 2000;
            }
            let newRequest = new GetStatusRequest(message, resolve,
                reject, message.getDomain(),
                message.getTaskId(),  previousValue,
                status === 'INIT'? 1 : statusMessage.getCount() + 1,
                statusMessage ? statusMessage.getPid() : result.pid);
            setTimeout(() => {
                me.performRequest(newRequest)
                    .then((result) => {
                        me.handleLongProcessingRequest(message, resolve, reject, newRequest, result);
                    })
                    .catch(reject);
            }, newRequest.getTime());
        } else {
            //Status is 'STOPPED'
            statusMessage.getInitialMessageReject()('L\'utilisateur a annulé l\'appel');
        }
    }

    addPendingRequest(request) {
        this.requests.push(request);
    }

    recallRequests() {
        let allRequest = [];
        for (var key in this.requests) {
            var currentRequest = this.requests[key];
            // Don't put the login request back in the stack
            if(currentRequest.message.service !== 'login') {
                allRequest.push(this.performRequest(currentRequest.message).then(currentRequest.resolve).catch(currentRequest.reject));
            }
        }
        this.requests = [];
        return Promise.all(allRequest);
    }

    private setSessionTimeout() {
        // Reset the timeout
        if(this.sessionTimeoutId !== undefined) {
            window.clearTimeout(this.sessionTimeoutId);
        }
        let timeout = User.getSessionTimeout() + 15000;
        this.sessionTimeoutId = window.setTimeout(this.executeSessionTimeout, timeout);
    }

    private executeSessionTimeout() {
        App.views.applicationLogin.tokenConnect()
    }
}

const singleInstance = Server.Instance;

export default singleInstance;