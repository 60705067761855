import Row from 'models/listing/Row';
import DataRow from 'models/listing/DataRow';
import RuptureRow from 'models/listing/RuptureRow';

import { Header } from 'models/listing/Header';
import DynamicGridFormatter from 'models/listing/formatters/DynamicGridFormatter';

import Server from 'server/Server';

import Contexts from 'collections/Contexts';
import Context from 'models/context/Context';
import GetGridDataRequest from 'server/protocol/request/task/GetGridData';

import App from 'App';
import User from 'User';
import _ from 'lodash';
import Backbone from 'backbone';
import LoadingMask from 'views/loading/LoadingMask';
import { PopupMenu } from '../../parametrage/structures/PopupMenu';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import StringUtils from '../../utils/StringUtils';
import { DataField } from '../../parametrage/structures/DataField';

class Listing extends Backbone.Model {

    initialize(options){
        this.setFormatter(options.formatter);
        let formatter = this.get('formatter');

        if(options.headers){
            this.setHeaders(options.headers, formatter);
        }

        if(formatter){
            if(formatter.isLocal()){
                this.set('sourceItems', options.items || []);
                this.set('items', formatter.formatRows(options.items || [], this.getHeaders));
            }
        }else{
            this.set('items', options.items || []);
        }

        this.set('start', options.start || 0);
        this.set('end', options.end || 20);

        this.set('currentPage', -1);

        this.set('hasToFetchTpl', options.fetchTemplate === true);

        this.set('sessionVars', options.sessionVars ? options.sessionVars : {});

        this.set('contexts', new Contexts());
    }

    setFormatter(formatter){
        if(formatter && formatter.type){
            let formatterType = formatter.type;
            if(formatterType === 'dynamicgrid'){
                this.set('formatter', new DynamicGridFormatter(formatter));
            }else{
                console.error('[listing] Unknown formatter type ' + formatterType);
            }
        }
    }

    getFormatter(){
        return this.get('formatter');
    }

    getRow(indexOrAttribute, attributeValue){
        let index = indexOrAttribute;
        if(typeof indexOrAttribute === 'string' && attributeValue !== undefined){
            index = _.findIndex(this.get('items'), function(item){
                return item.columns[indexOrAttribute].value === attributeValue;
            });
        }

        return this.buildRow(this.get('items')[index]);

    }

    getRows(by){
        var rows = this.buildRowArray(this.get('items'));
        if(by === undefined || by.trim() === ''){
            return rows;
        }else{
            return _.sortBy(rows, this.mapByToSortFunctions(by));
        }
    }

    getRowsCount(){
        return this.get('items').length;
    }

    mapByToSortFunctions(by){
        var arrayOfSortsCrit = by;

        if(typeof by === 'string'){
            arrayOfSortsCrit = [by];
        }

        return _.map(by, function(item){
            return function(row){
                return row.getColumnsValues()[item];
            };
        });
    }

    buildRowArray(rawRows){
        var returnRows = [];
        for(var key in rawRows){
            var newRow = this.buildRow(rawRows[key]);
            newRow.setHeaders(this.getHeaders());
            newRow.setActions(this.getActions());
            returnRows.push(newRow);
        }
        return returnRows;
    }

    buildRow(rawRow){
        var rowToReturn;
        if(Row.parseRowLevel(rawRow.styles)){
            rowToReturn = new RuptureRow({
                rawJSON : rawRow
            });
        }else{
            rowToReturn = new DataRow({
                rawJSON : rawRow
            });
        }

        rowToReturn.setHeaders(this.getHeaders());
        rowToReturn.setActions(this.getActions());
        return rowToReturn;
    }

    getId(){
        return this.get('gridId');
    }

    getTaskId(){
        return this.get('taskId');
    }

    getDomainContext() {
        return this.get('domainContext');
    }

    getFormatId(){
        return this.get('formatterId');
    }

    getHeaders(){
        return new Header(this.get('headers'));
    }

    setHeaders(newHeaders, formatter){
        if(!formatter){
            this.set('headers', newHeaders);
        }else{
            this.set('headers', formatter.buildHeaders(newHeaders));
        }
    }

    appendRows(newRows){
        this.set('items', this.get('items').concat(newRows));
        this.trigger('rowsAdded', this.buildRowArray(newRows));
    }

    updateRow(index, newData){
        this.get('items')[index] = newData;
        this.trigger('rowUpdated', index, this.buildRowArray([this.get('items')[index]]));
    }

    removeRow(index){
        var removed = this.get('items').splice(index, 1);
        this.trigger('rowRemoved', removed, index);
    }

    setFilterData(fieldsMap, customText){
        this.set('filterData',{
            values : fieldsMap,
            text : customText
        });
    }

    fetchNextPage(isMainGrid) {
        var pageSize = this.get('end') - this.get('start');
        var currentPage = this.get('currentPage') + 1;
        this.set('currentPage', currentPage);

        var filterData = this.get('filterData') || {};

        //Building the server request
        let dataRequest = new GetGridDataRequest(this.getDomainContext(),this.get('taskId'), this.get('gridId'));
        dataRequest.setIsMainGrid(isMainGrid ? isMainGrid : 'false');
        dataRequest.setFormatterId(this.get('formatterId'));
        dataRequest.setFilterId(this.get('filterId'));
        dataRequest.setRange(pageSize > 0 ? currentPage * pageSize : 0, pageSize > 0 ? (currentPage+1)* pageSize : -1);
        dataRequest.setSessionVars(this.get('sessionVars'));

        let filterContext = new Context({contextId: 'filter'});
        filterContext.addParameter('customText', filterData.text);
        filterContext.addFields(filterData.values);
        dataRequest.setFilterContext(filterContext);

        let recordContext = new Context();
        this.trigger('listing.onPrepareContext', recordContext);
        if(recordContext.getContextId() === 'page') {
            dataRequest.setPageContext(recordContext);
        } else {
            dataRequest.setRecordContext(recordContext);
        }
        
        let me = this;
        let loadingReqId;
        LoadingMask.requestLoading('Chargement du navigateur')
            .then((reqId) => {
                loadingReqId = reqId;
                return Server.performRequest(dataRequest);
            })
            .then((response) => {
                let datafieldsMap = {};
                let combosMap = {};
                
                this.getHeaderInformations(response, datafieldsMap, combosMap)
                    .then(() => {
                        me.handleHumanValues(response.items, datafieldsMap, combosMap)
                        this.onPageReceived(response);
                    });
            })
            .catch(App.displayErrorMessage)
            .then(() => { LoadingMask.hide(loadingReqId); });
    }

    getHeaderInformations(response, datafieldsMap, combosMap) {
        let allPromises = [];
        let me = this;
        for(let headerId in response.headers) {
            let headerItem = response.headers[headerId];
            let dfPromise = new Promise((accept, reject) => {
                var dfProm = headerItem.datafieldId === undefined || headerItem.datafieldId === '' ?
                    new Promise((ok) => ok(undefined)) :
                    CClientConfiguration.getDataField(headerItem.datafieldId, me.getDomainContext());
                dfProm
                    .then((datafield) => {
                        if(datafield !== undefined) {
                            datafieldsMap[headerItem.dataIndex] = datafield;
                            if(headerItem.format === 'Select') {
                                CClientConfiguration.getComboByDataField(datafield,User.getLocale(), me.getDomainContext(), me.getTaskId())
                                    .then((combo) => {
                                        combosMap[headerItem.dataIndex] = combo;
                                        accept();
                                    })
                                    .catch(() => {
                                        combosMap[headerItem.dataIndex] = null;
                                        accept();
                                    });
                            } else {
                                accept();
                            }
                        } else {
                            // the datafield was not found in the task and in the table
                            // try in the grid
                            me.searchInGridView(headerItem.datafieldId, headerItem.dataIndex, datafieldsMap, combosMap, headerItem)
                                .then(accept);
                        }
                    })
                    .catch(() => {
                        // the datafield was not found in the task and in the table
                        // try in the grid
                        me.searchInGridView(headerItem.datafieldId, headerItem.dataIndex, datafieldsMap, combosMap, headerItem)
                            .then(accept);
                    });
            });
            allPromises.push(dfPromise);
        }
        return Promise.all(allPromises);
    }

    searchInGridView(dataFieldId, fieldId, datafieldsMap, combosMap, headerItem) {
        let me = this;
        return new Promise((accept, reject) => {
            CClientConfiguration.getGridDataField(dataFieldId,me.getDomainContext(),me.get('gridId'))
                .then((datafield) => {
                    datafieldsMap[fieldId] = datafield;
                    if(datafield && datafield.getFormat() === 'Enum') {
                        CClientConfiguration.getCombo(datafield.getEnumName(),User.getLocale(), me.getDomainContext(),null)
                            .then((combo) => {
                                combosMap[fieldId] = combo;
                                accept();
                            });
                    } else {
                        accept();
                    }
                })
                .catch(() => {
                    //Datafield not found in grid, trying to build a fake one
                    datafieldsMap[fieldId] = new DataField({
                        id: dataFieldId || 'DUMMY_DF',
                        format: headerItem.format,
                        source: 'TaskOnly',
                        isHeader: 'false',
                        isTitle: 'false',
                        isKey: 'false',
                    }, null, null, null, dataFieldId, me.getDomainContext());

                    if (datafieldsMap[fieldId].getFormat() === 'Enum') {
                        CClientConfiguration.getCombo(headerItem.enumName, User.getLocale(), me.getDomainContext(), null)
                            .then((combo) => {
                                combosMap[fieldId] = combo;
                                accept();
                            });
                    } else {
                        accept();
                    }
                });
        });
    }

    handleHumanValues(items, datafieldsMap, comboMap) {
        for(let itemId in items) {
            let item = items[itemId];
            let values = item.columns;
            for(let attr in values) {
                let value = StringUtils.formatValue(values[attr].value, datafieldsMap[attr], comboMap[attr], false);
                values[attr].humanValue = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
            }
        }
    }

    onPageReceived(response){
        if(this.get('headers') === undefined){
            this.setHeaders(response.headers, response.formatter);
            this.trigger('headersUpdated', this.get('headers'));
        }

        let hasVars = false;
        for(let key in response.sessionVars){
            hasVars = true;
            break;
        }
        if(hasVars){
            this.trigger('receivedVars', response.sessionVars);
        }

        this.set('serverPageStart', response.start);
        this.set('serverPageEnd', response.end);

        this.appendRows(response.items);
    }

    getActions(){
        return this.get('actions');
    }

    resetData(noTrigger){
        this.set('items', []);
        this.set('currentPage', -1);
        if(noTrigger !== false){
            this.trigger('reset');
        }
    }

    setSessionVar(varName, varValue){
        this.get('sessionVars')[varName] = varValue;
    }

    hasMorePages(lastPackageSize){
        var pageSize = this.get('end') - this.get('start');
        var serverPageStart = this.get('serverPageStart');
        var serverPageEnd = this.get('serverPageEnd');
        if(serverPageStart === serverPageEnd && serverPageEnd === -1) {
            // Explicite answer from the server that all the data are sent
            return false;
        }
        var serverPageSize = serverPageEnd - serverPageStart;
        return pageSize <= 0 || serverPageSize < 0 ? false : lastPackageSize >= pageSize && this.get('items').length > 0;
    }

    addContext(context){
        this.get('contexts').addContext(context);
    }

    hasData(){
        let data = this.get('items');
        return data && data.length > 0;
    }

    setPopupMenu(popupMenu) {
        this.popupMenu = popupMenu;
    }
}

export default Listing;
